@import "../vars/color";

@mixin colors($entity: 'common') {
  // функция colors, которая принимает параметр $entity, по умолчанию "common"

  @if $entity == 'test' {
    border: 3px solid green;
  }

  @each $color in $colors {
    &-#{nth($color, 1)} {
      /*
        используется в родительском классе.
        В качестве вывода: parent-class-названиеЦвета
        название - первое значение
        сам цвет - второе значение
       */

      @if $entity == 'input' {
        .mat-form-field-flex {
          background: nth($color, 2);
        }
      }

      @if nth($color, 1) == 'none' {
        border: 1px solid var(--color-snow);
      }

      @if $entity == 'button' {
        // background: nth($color, 2);
        background-color: nth($color, 2) !important;
      }

      @if $entity == 'icon-fill' {
        fill: nth($color, 2);
      }

      @if $entity == 'icon-stroke' {
        stroke: nth($color, 2);
      }

      @if $entity == 'button-disabled' {
        background: nth($color, 2);
        color: var(--color-segment) !important;
      }

      @if $entity == 'chip' {
        background: nth($color, 2);
      }
    }
    .#{nth($color, 1)} {
      @if $entity == 'colorText' {
        color: nth($color, 2);
      }
    }
  }
}
