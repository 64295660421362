@import "vars/general";
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import "vars/palette";
@import "mixins/color";

:root {
  @include colors("colorText");
  --mdc-filled-text-field-error-caret-color: #F44336; // red;
  --mdc-filled-text-field-caret-color: #673AB7; //black;

  --mdc-outlined-text-field-error-caret-color: #F44336; // red;
  --mdc-outlined-text-field-caret-color: #673AB7; // black;

  --mdc-filled-button-label-text-color: #000;
}

// button
.mat-button-base, .mat-mdc-button.mat-mdc-button-base, .mat-mdc-button-base {
  border-radius: $button-r !important;
  width: 100%;
  max-width: 343px;
  height: 60px;

  &.mat-mdc-unelevated-button:not(:disabled) {
    &.mat-accent {
      background-color: var(--color-accent);
    }
    &.snow {
      color: var(--color-snow);
    }
    color: black; // --mdc-filled-button-label-text-color;
  }
  .mdc-button__label {
    display: flex;
    align-items: center;
    column-gap: 4px;
  }

  &.mat {
    @include colors("button");

    &-button-disabled, &-mdc-button-disabled {
      &.mat {
        @include colors("button-disabled");
      }
    }
  }

  &.footer {
    border-radius: 0 0 $button-r $button-r !important;
  }

  &.corner {
    border-radius: $button-r $button-r 0 $button-r !important;
  }

  &.mat-fab {
    .mat-button-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      line-height: 22px;
    }
  }

  &.shadow {
    box-shadow: 0 16px 24px rgba(0, 0, 0, 0.06), 0 2px 6px rgba(0, 0, 0, 0.04), 0 0 1px rgba(0, 0, 0, 0.04);
  }

  &.mini {
    width: 100%;
    height: max-content;

    .mat-button-wrapper, .mdc-button__label {
      white-space: nowrap; // pre-wrap;
      display: flex;
      line-height: normal;
      margin-block-start: 5px;
      margin-block-end: 5px;
    }
  }
  &.center {
    .mat-button-wrapper, .mdc-button__label {
      justify-content: center;
    }
  }
}

.mat-icon-button {
  .mat-button-wrapper {
    display: flex;
    justify-content: center;
  }
}
//

// bottom-sheet
.eng-panel-bottom-sheet {
  $border-radius: 30px;
  border-top-right-radius: $border-radius !important;
  border-top-left-radius: $border-radius !important;
  padding-block-start: 25px !important;
  padding-bottom: 0 !important;
  min-width: calc(100% - 36px) !important;
  margin-inline-start: 16px;
  margin-inline-end: 16px;

  @media
  only screen and #{$Medium},
  only screen and #{$Large},
  only screen and #{$XLarge} {
    border-bottom-right-radius: $border-radius !important;
    border-bottom-left-radius: $border-radius !important;
    overflow: visible !important;

    :after {
      content: '';
      display: block;
      height: 0;
      clear: both;
      visibility: hidden;
    }
  }

  &::-webkit-scrollbar-track {
    background: none;
    margin-block-start: 27px;
  }
}

.cdk-overlay-backdrop.eng-bottom-sheet {
  background-color: var(--color-mainbg);
  opacity: 0;
}

.cdk-overlay-container {
  .cdk-global-overlay-wrapper {
    @media
    only screen and #{$Medium},
    only screen and #{$Large},
    only screen and #{$XLarge} {
      align-items: center !important;
    }
  }
}
//

// eng-input
mat-form-field.eng-input, mat-mdc-form-field.eng-input {
  width: 100%;
  .mdc-text-field--filled {
    border-radius: 50px;
  }
  .mdc-line-ripple {
    display: none;
  }
  .mat-mdc-form-field-flex {
    background-color: var(--color-snow);
  }

  &.mat {
    @include colors("input");
  }

  &.ng-touched.ng-invalid {
    .mat-form-field-flex, .mat-mdc-form-field-flex {
      border: 3px solid var(--color-error);
    }
  }

  .mdc-text-field {
    padding: 0;
  }
  .mat-form-field, .mat-mdc-form-field, mat-mdc-text-field {
    &-underline {
      visibility: hidden;
    }

    &-flex {
      border-radius: $button-r;
      box-shadow: 0 16px 24px rgba(0, 0, 0, 0.06), 0 2px 6px rgba(0, 0, 0, 0.04), 0 0 1px rgba(0, 0, 0, 0.04);
      padding-top: 0;

      padding-inline-start: 20px;
      padding-inline-end: 20px;
      height: 60px;
      align-items: center;
      text-align: start;

      border: none;
    }

    &-wrapper {
      padding: 0;
    }

    &-infix {
      border: 0;
      padding: 0;
      text-align: start;
      min-height: unset;

      input {
        color: var(--color-dark);
        &::placeholder {
          color: var(--color-segment);
        }
      }
    }

    &-suffix {
      margin-inline-start: 20px;
    }
  }

  &.no-shadow {
    .mat-form-field-flex, .mat-mdc-form-field-flex {
      box-shadow: none;
    }
  }

  &.border {
    .mat-form-field-flex, .mat-mdc-form-field-flex {
      border: 1px solid var(--color-primary);
    }
  }

  &.start-bg {
    .mat-form-field, .mat-mdc-form-field {
      &-flex {
        padding-inline-start: 0;
      }

      &-prefix {
        height: 100%;
        border-radius: $button-r 0 0 $button-r;
        overflow: hidden;
      }

      &-suffix {
        margin-inline-start: 20px;
      }
    }
  }
}
//

// checkbox
mat-checkbox {
  .mat-checkbox, .mdc-checkbox {
    flex: 0 0 25px !important;
    width: 25px !important;
    height: 25px !important;

    &-inner-container {
      width: 25px !important;
      height: 25px !important;
      margin-inline-end: 20px !important;
    }

    &.round {
      .mat-checkbox-frame,
      .mat-checkbox__background {
        border-radius: 5px !important;
      }
    }

  &-background,
  &-frame,
  &__background {
    border: none !important;
    border-radius: 5px !important;
    background: var(--color-snow) !important;
  }
  &-frame,
  &__background {
    // box-shadow: 0 16px 24px rgba(0, 0, 0, 0.06), 0 2px 6px rgba(0, 0, 0, 0.04), 0 0 1px rgba(0, 0, 0, 0.04);
    box-shadow: 0 16px 24px rgba(0, 0, 0, 0.1), 0 2px 6px rgba(0, 0, 0, 0.2), 0 0 1px rgba(0, 0, 0, 0.1);
  }

    &-checkmark-path, &__checkmark-path {
      stroke: var(--color-accent) !important;
      transform: scale(0.858, 1) translate(3px, -1px) !important;
      stroke-width: 3px !important;
    }

    &-checked {
      .mat-checkbox-frame,
      .mat-checkbox__background {
        border: none !important;
      }
    }

    &-label {
      white-space: pre-wrap;
      text-align: start;
    }

    &.ng-touched.ng-invalid {
      .mat-checkbox__background {
        border: 3px solid var(--color-error) !important;
      }
    }
  }
}
//

// chips
mat-chip-list, mat-chip-listbox {
  &.eng-chip-list {
    .mat-chip-list-wrapper {
      margin: 0;
      justify-content: flex-start;
    }
  }
  mat-chip, mat-chip-option {
    margin-block-start: 4px;
    margin-block-end: 4px;
    margin-inline-start: 4px;
    margin-inline-end: 4px;
    line-height: normal;
    &.mdc-evolution-chip--with-avatar.mdc-evolution-chip--with-primary-graphic .mat-mdc-chip-action {
      flex-direction: row-reverse;
      padding-left: 12px;
      padding-right: 0;
    }
  }
  .mat-chip.mat-standard-chip, .mat-mdc-chip.mat-mdc-standard-chip {
    &:focus::after {
      opacity: 0;
    }
    &.mat-chip-disabled, &.mat-mdc-chip-disabled {
      opacity: 1;
    }
    &.eng-chip {
      &-color {
        @include colors("chip");
      }

      &-padding {
        &-s {
          padding: 3px 8px;
        }

        &-xs {
          min-width: 30px;
          // padding: 8px 11px;
        }

        &-xxs {
          padding: 4px 6px;
        }
      }
    }
    button {
      margin-block-start: inherit;
    }
  }
}
//

// textarea
.eng-textarea {
  font-size: 18px !important;
  .mat-form-field-infix, .mat-mdc-form-field-infix {
    max-height: 138px;
    //max-height: 100 + 14 * 2 + 2 * 2px;
    overflow: auto;
    padding-right: 2px;
    &::-webkit-scrollbar {
      width: 4px;
      overflow-y: scroll;
      background: none;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--color-primary);
      border-radius: 5px;
    }
    &::-webkit-scrollbar-track {
      background: none;
      margin-top: 11px;
      margin-bottom: 11px;
    }
  }

  textarea, textarea::placeholder {
    font-size: 18px !important;
    color: var(--color-segment);
    overflow: hidden;
    display: block;
    resize: none !important;
    // margin-top: -4px;
  }

  textarea#title {
    margin-top: -10px;
  }
  textarea#description {
    margin-top: -4px;
  }

  .mdc-text-field--outlined .mat-mdc-form-field,
  .mat-form-field,
  &.mat-mdc-form-field,
  &.mat-form-field {
    text-align: start;
    width: 100%;

    &-infix {
      border-top: 0;
      // padding: 11px 0 11px 0;
      padding: 3px 0;
    }
    &-flex {
      padding-top: 0;
      padding-bottom: 0;
      padding-inline: 2px;
      // padding-inline-start: 20px;
      // padding-inline-end: 20px;
    }
    &-wrapper, .mat-mdc-text-field-wrapper {
      padding-bottom: 0;
      margin-block: 0.25em;
    }

    &-appearance-outline {
      .mdc-notched {
        &-outline {
          border-radius: 25px;
          box-shadow: 0 16px 24px rgba(0, 0, 0, 0.06), 0 2px 6px rgba(0, 0, 0, 0.04), 0 0 1px rgba(0, 0, 0, 0.04);
          background-color: var(--color-snow);
          z-index: -1;
          &__leading {
            border-radius: 25px 0 0 25px;
            min-width: 30px;
            border: 0;
          }
          &__notched {
            border-radius: 0 25px 25px 0;
            border: 0;
          }
          &__trailing {
            border: 0;
          }
        }
      }
    }

    &.ng-touched.ng-invalid {
      .mat-form-field, .mdc-notched {
        &-outline {
          border: 3px solid var(--color-error) !important;
        }
      }
    }

    &.border {
      .mdc-notched-outline {
        border: 1px solid var(--color-primary);
      }
    }

    &.no-shadow {
      .mdc-notched-outline {
        box-shadow: none;
      }
    }
  }
}
//
//mat-menu
.mat-mdc-menu-panel {
  border-radius: 25px !important;
  .mat-mdc-menu-content {
    .mat-mdc-menu-item {
      min-height: unset;
      height: min-content;
      text-align: end;
      .mat-mdc-menu-item-text {
        line-height: 25px;
        align-items: center;
        display: flex;
        justify-content: flex-start;
        column-gap: 8px;
      }
    }
  }
}
//

.cdk-overlay-connected-position-bounding-box {
  direction: inherit;
}
