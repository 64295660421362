$mat-primary: (
        50: var(--color-primary),
        100: var(--color-primary),
        500: var(--color-primary),
        700: var(--color-primary),
        contrast: (
                50: var(--color-dark),
                100: var(--color-dark),
                200: var(--color-dark),
                300: var(--color-snow),
                400: var(--color-snow),
                500: var(--color-snow),
                600: var(--color-snow),
                700: var(--color-snow),
                800: var(--color-snow),
                900: var(--color-snow),
                A100: var(--color-dark),
                A200: var(--color-snow),
                A400: var(--color-snow),
                A700: var(--color-snow),
                main: var(--color-dark),
                lighter: var(--color-dark),
                darker: var(--color-dark),
        )
);
$mat-accent: (
        50: var(--color-accent),
        100: var(--color-accent),
        500: var(--color-accent),
        700: var(--color-accent),
        contrast: (
                50: var(--color-dark),
                100: var(--color-dark),
                200: var(--color-dark),
                300: var(--color-dark),
                400: var(--color-dark),
                500: var(--color-snow),
                600: var(--color-snow),
                700: var(--color-snow),
                800: var(--color-snow),
                900: var(--color-snow),
                A100: var(--color-dark),
                A200: var(--color-snow),
                A400: var(--color-snow),
                A700: var(--color-snow),
                main: var(--color-dark),
                lighter: var(--color-dark),
                darker: var(--color-dark),
        )
);
$mat-warn: (
        50: var(--color-repeat),
        100: var(--color-repeat),
        500: var(--color-repeat),
        700: var(--color-repeat),
        contrast: (
                50: var(--color-dark),
                100: var(--color-dark),
                200: var(--color-dark),
                300: var(--color-snow),
                400: var(--color-snow),
                500: var(--color-snow),
                600: var(--color-snow),
                700: var(--color-snow),
                800: var(--color-snow),
                900: var(--color-snow),
                A100: var(--color-dark),
                A200: var(--color-snow),
                A400: var(--color-snow),
                A700: var(--color-snow),
                main: var(--color-dark),
                lighter: var(--color-dark),
                darker: var(--color-dark),
        )
);

$colors:
        "accent" var(--color-accent),
        "accent-light" var(--color-accent-light),
        "accent-unclear" var(--color-accent-unclear),
        "primary" var(--color-primary),
        "repeat" var(--color-repeat),
        "berry" var(--color-berry),
        "dark" var(--color-dark),
        "snow" var(--color-snow),
        "facebook" var(--color-facebook),
        "google" var(--color-google),
        "error" var(--color-error),
        "segment" var(--color-segment),
        "mainbg" var(--color-mainbg),
        "none" var(--color-none);

:root {
        --color-accent-RGB: 239, 133, 95; //#EF855F
        --color-accent: #EF855F; //#EF855F
        --color-accent-light: #F19C7D; //#F19C7D
        --color-accent-unclear: #DFB3A4; //#DFB3A4
        --color-primary-RGB: 210, 243, 253; //#D2F3FD
        --color-primary: #D2F3FD; //#D2F3FD
        --color-repeat-RGB: 255, 208, 80; //#FFD050
        --color-repeat: #FFD050; //#FFD050
        --color-berry-RGB: 227, 131, 171; //#E383AB
        --color-berry: #E383AB; //#E383AB
        --color-dark-RGB: 63, 64, 73; //#3F4049
        --color-dark: #3F4049; //#3F4049
        --color-snow-RGB: 255, 255, 255;
        --color-snow: #ffffff;
        --color-facebook-RGB: 59, 89, 152;
        --color-facebook: rgb(59, 89, 152);
        --color-google-RGB: 219, 68, 55;
        --color-google: rgb(219, 68, 55);
        --color-error-RGB: 219, 68, 55; //#DA364C
        --color-error: #DB4437; //#DA364C
        --color-success: #0EC036;
        --color-segment: #B0B0B0;
        --color-mainbg: #FFF3EF;
        --color-none-RGB: none;
        --color-none: none;
}
