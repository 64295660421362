::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-button {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  background: var(--color-berry);
  border: 20px none #ffffff;
  border-radius: 21px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--color-berry);
}

::-webkit-scrollbar-thumb:active {
  background: var(--color-berry);
}

::-webkit-scrollbar-track {
  background: var(--color-snow);
  border: 3px solid #ffffff;
  border-radius: 50px;
}

::-webkit-scrollbar-track:hover {
  background: var(--color-berry);
}

::-webkit-scrollbar-track:active {
  background: var(--color-berry);
}

::-webkit-scrollbar-corner {
  background: transparent;
}

* {
  scrollbar-color: var(--color-berry) #fff;
  scrollbar-width: thin;
}
