@import "reset";
@import "fonts";
@import "material";
@import "scrollbar";

h1, .h1 {
  font-size: 45px !important;
}

h2, .h2 {
  font-size: 35px !important;
}

h3, .h3 {
  font-size: 30px !important;
}

h4, .h4 {
  font-size: 25px !important;
}

h5, .h5 {
  font-size: 20px !important;
}

button.h6 {
  height: 60px;
}

h6, .h6 {
  font-size: 18px !important;
}

.h7 {
  font-size: 16px !important;
}

.h8 {
  font-size: 15px !important;
}

.h9 {
  font-size: 14px !important;
}

.h10 {
  font-size: 13px !important;
}

.bold-3 {
  font-weight: 300 !important;
}

.normal {
  font-weight: 400 !important;
}

.bold-5 {
  font-weight: 500 !important;
}

.bold {
  font-weight: 700 !important;
}

.text-align {
  &-center {
    text-align: center;
  }
  &-right {
    text-align: right;
  }
  &-left {
    text-align: left;
  }
}

.line-height {
  &-17 {
    line-height: 17px;
  }
  &-18 {
    line-height: 18px !important;
  }
  &-21 {
    line-height: 21px !important;
  }
  &-22 {
    line-height: 22px !important;
  }
  &-24 {
    line-height: 24px;
  }
  &-25 {
    line-height: 25px;
  }
  &-35 {
    line-height: 35px;
  }
  &-40 {
    line-height: 40px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

$max-width: 343px;
eng-input-phone, eng-select, eng-select-multiple, mat-form-field, mat-dialog-container {
  max-width: $max-width;
}

.mat-button.cdk-keyboard-focused,
.mat-button.cdk-program-focused,
.mat-icon-button.cdk-keyboard-focused,
.mat-icon-button.cdk-program-focused,
.mat-stroked-button.cdk-keyboard-focused,
.mat-stroked-button.cdk-program-focused,
.mat-flat-button.cdk-keyboard-focused,
.mat-flat-button.cdk-program-focused{
  &:not(:focus), &:not(:active) {
    .mat-button-focus-overlay {
      opacity: 0;
    }
  }
}

.list-style-inside {
  list-style-position: inside;
}


.d-flex {
  display: flex;
}
.flex-column {
  flex-direction: column;
}
.justify-content-center {
  justify-content: center !important;
}

.cl-g-5px {
  column-gap: 5px;
}
